@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400&display=swap);
html {
  /* --textColor: #F4F4EA; */
--textColor:#dee0e4;
  /* --textColor: #E3E0F3; */
  --bgColor: #000000;
  /* --bgColor:#171c28; */
  --highlightColor: #7dba5c;
  --sectionBgColor: #1a1a1a;
  --shadeColor: #aeaeae;
  --overlayColor:#7dba5cCC;
  /* --navbarColor:#131B25; */
  --navbarColor:#000000;
  --dividerColor:#333333;
  --whiteColor:#ffffff;
  --fontFamily: "Calibre","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  --navBarFont:Montserrat, Montserrat, sans-serif;
}
/* 
#root::-webkit-scrollbar {
  display: none;
} */

.modal-backdrop {
    background-color:black !important; /* Change the color and opacity as needed */
}

/* Hide scrollbar for IE, Edge and Firefox */

.textFont{
  font-size: 1rem;
}


.headingFont{
  font-size: 3rem;
}

.defaultMargin{
  margin-left: 3.5%; 
    margin-right: 2%; 
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c2915;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #405f30;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7dba5c;
  border-radius: 12px;
}

.content::-webkit-scrollbar {
  width: 0 !important
}

.content {
  overflow: -moz-scrollbars-none;
}


.content{
  background-color:var(--bgColor);
  /* overflow-y: scroll; */
}

.headerUnderline {
  width: 100px;
  height: 6px;
  background-color: var(--highlightColor);
  margin: 20px 0 20px 0;
}

.smallHeaderUnderline {
  width: 100px;
  height: 4px;
  background-color: var(--highlightColor);
  margin: 20px 0 20px 0;
}

.centerUnderline{
  align-content: center;
}

/* .headerUnderline:after {
  content: '';
  position: absolute;
  width: 75px;
  height: 6px;
  background-color: var(--highlightColor);
  /* background: yellow; 
} */



/* .headerUnderline:before {
  content: '';
  position: absolute;
  width: 40%;
  left: 60%;
  height: 6px;
  background: green;
} */



.contentDivider {

  width: auto;
  height: 1px;
  background-color: var(--dividerColor);
 
  /* margin: 50px 0 0 0; */
  clear: both;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
  font-family: var(--fontFamily);
  color: var(--textColor);
  /* text-transform: uppercase; */
}

.margin-bottom50 {
  margin-bottom: 50px;
}

.margin-bottom40 {
  margin-bottom: 40px;
}

.margin-bottom30 {
  margin-bottom: 30px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom10 {
  margin-bottom: 10px;
}

.margin-top50 {
  margin-top: 50px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-top30 {
  margin-top: 30px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top10 {
  margin-top: 10px;
}

.no-border-bottom {
  border-bottom: none !important;
}

.margin-right30 {
  margin-right: 30px;
}


.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}

.sidebarList {
  height: auto;
  width: auto;
  padding-left: 29%;
  position: absolute;
  top: 25%;
  padding-top: 15%
  /* bottom:20%; */
  /* border-right: #111111 1px solid; */
}


#profileName {
  text-align: center;
  color: #ffffff;
  margin: 10% 0 10% 0;
  letter-spacing: 0.3px;
  font-weight: bold;
  font-size: 35px;
  text-decoration: none;
  cursor: pointer;
  padding-bottom: 20px;
}

.media{
  vertical-align: middle;
}


.sidebarList .row {
  margin: 0;
  
  padding: 10px 0 10px 30px;
  list-style: none;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px;
  list-style-type: none;
  text-decoration:none;
  margin: 0;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin: 0px 0 10px 0;
  display: flex;
  cursor: pointer;
  transition: all 0.2s linear;
  filter: grayscale(1); /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -o-filter: grayscale(1); 
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
}


.sidebarList .nav-title {
  flex: 70% 1;
  -webkit-text-decoration: solid;
          text-decoration: solid;
  /* font-weight: normal; */
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  line-height: 15.5px;
  font-family: var(--navBarFont);
  letter-spacing: 1.5px;
  display: inline;
  place-items: center;
  padding-left: 20px;
  transition: all 0.2s ease-in-out;
  color: var(--textColor);
  font-size: 15px;
}

.sidebarList .row:hover {
  cursor: pointer;
  /* font-weight: bold; */
  transition: all 0.2s ease-in-out;
  filter: none;
  -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
  -o-filter: grayscale(0); 
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  color: var(--highlightColor);
  transform: scale(1.09);
  -webkit-transform: scale(1.09);
  -o-transform: scale(1.09);
  -moz-transform: scale(1.09);
  -ms-transform: scale(1.09);
}

.sidebarList .row:hover .nav-title {
  font-weight: bold;
  color: var(--highlightColor);
}

.sidebarList .active li{
  -webkit-text-decoration: solid;
          text-decoration: solid;
  /* font-weight: bold; */
  filter: none;
  -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
  -o-filter: grayscale(0); 
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  color: var(--highlightColor);
  transform: scale(1.09);
  -webkit-transform: scale(1.09);
  -o-transform: scale(1.09);
  -moz-transform: scale(1.09);
  -ms-transform: scale(1.09);
  transition: all 0.2s ease-in-out;
}

.sidebarList .active .nav-title{
  font-size: 15.5px;
  color: var(--highlightColor);
  font-weight: bold;
 
}

.MuiDrawer-paper {
  z-index: 0;
}

#home {
  min-height: 100vh;
  background-color: var(--bgColor);
  color: var(--textColor);
  font-size: 20px;
  font-family: var(--fontFamily);
}

#home .content-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem;
  width: 100%;
}

#home .hey-section {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

#home .heyImage {
  width: 150px;
  height: 75px;
}

#home .name {
  font-size: 70px;
  color: #bad0f7;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.1;
  font-family: Montserrat, "Montserrat", sans-serif;
  margin: 0;
}

#home .description {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

#home .social-media-div {
  display: flex;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

#home .icon-button span {
  color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  height: 50px;
  width: 50px;
  margin: 0 5px;
  transition: all 0.3s ease;
}

#home .icon-button:hover span {
  background-color: #ffffff;
}

#home .icon-button:hover .icon {
  color: #000000;
}

#home .wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  font-size: 50px;
}

@keyframes wave-animation {
  0% { transform: rotate(0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate(0.0deg) }
  100% { transform: rotate(0.0deg) }
}

#home .resumeBtn {
  background-color: var(--textColor);
  color: var(--bgColor);
  padding: 0.5rem 2rem;
  transition: all 0.3s ease;
}

#home .resumeBtn:hover {
  background-color: var(--highlightColor);
  color: var(--whiteColor);
}

#home .resumeLink {
  text-decoration: none;
}

@media (min-width: 1400px) {
  #home .content-wrapper {
    max-width: 1400px;
  }
  #home .name {
    font-size: 80px;
  }
  #home .description {
    font-size: 1.4rem;
    max-width: 1000px;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  #home .content-wrapper {
    max-width: 1000px;
  }
  #home .name {
    font-size: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #home .content-wrapper {
    max-width: 800px;
  }
  #home .name {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  #home .content-wrapper {
    max-width: 100%;
    padding: 1rem;
    padding-top: 30%;
  }
  #home .wave{
    display: none;
  }
  #home .name {
    font-size: 50px;
  }
  #home .description {
    font-size: 1rem;
    padding: 0 1rem;
  }
  #home .hey-section {
    flex-direction: column;
  }
}
#education_achievement{
width: 100%;
margin-bottom: 4%;
min-height: 100vh;
background-color: var(--bgColor);
color: var(--textColor);
padding-top: 1%;
font-size: 20px;
}

#education .media{
    margin-top:0;
}

.detailsRow{
  padding-top: 5px;
}

#achievements{
  padding-top: 3%;
}
#experience {
  /* position: relative; */
  /* padding: 20px; */
  margin-bottom: 4%;
  width: 100%;
  padding-top: 1%;
  min-height: 100vh;
  color: var(--textColor);
  background-color: var(--bgColor);
  font-size: 16px;
  
  /* background-color: var(--bgColor); */
  /* width: 100vw; */
}

#experience .offset-md-1 .offset-sm-1 .offset-lg-1 .offset-xl-1 {
  margin-left: 5%;
  margin-right: 5%;
}



/* .inner p {
  position: relative;
  padding: 0;
} */

.flot-left {
  float: left;
}
.flot-right {
  float: right;
}

/* .gray-bg {
  background-color: #1a1a1a;
}
.white-bg {
  background-color: #000000;
} */

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
  position: relative;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}


.experienceMainContainer .icon-block i {
  font-size: 60px;
  color: #7dba5c;
  margin: 0px 0 0;
}
.experienceMainContainer h5 {
  margin: 5px 0 7px 0;
}
.experienceMainContainer span {
  font-size: 16px;
}
.experience-details {
  float: left;
}
.media img {
  width: 70px;
  height: 70px;
  /* overflow: "hidden"; */
  border-radius: 50%;
  vertical-align: middle;
  float: left;
  padding-left: 0px;
  margin-left: 0px;
  padding-right: 0px;
}

#experience .skills-block {
  margin-bottom: 2px;
  margin-top: 5px;
}

#experience .content-skills {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#experience .content-skills li {
  background: #40484d;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 10px;
  color: rgba(255, 255, 255, 0.7);
  margin: 1px 1px;
  text-align: center;
  flex-grow: 1;
}
/* html, body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    overflow-x: hidden !important;
} */

#projects {
  width: 100%;
  padding-top: 1%;
  min-height: 100vh;
  background-color: var(--bgColor);
  color: var(--textColor);
  font-family: var(--fontFamily);
  margin-bottom: 4%;
  font-size: 16px;
}

#projects .projectLink{
  transition: all 0.1s ealinearse;
}

#projects .project-contribution{
  list-style-type: square !important;
}

.nav-pills .nav-item.active {
  color: var(--textColor);
  background: None;
  font-family: var(--fontFamily);
}

.nav-item {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  /* font-weight: bold; */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: None !important;
  color: var(--highlightColor) !important;
  font-weight: bold !important;
}


#projects .fade{
  transition: transform 0.5s linear;
  transition-property: opacity;
}

.nav-link {
  border: None;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: none;
  border: None;
  color: var(--highlightColor);
}

.nav-item a {
  color: var(--textColor);
  top: 0;
}

.nav-item:hover .nav-link:hover {
  top: 0;
  border: None;
  color: var(--highlightColor);
  transition: all 0.7s ease;
}

.tab-list {
  border: none;
}

#projects .card {
  border: 1px solid var(--textColor);
  background-color: transparent;
  min-height: 150px;
  max-height: 250px;
}

#projects .prImage {
  text-align: center;
}

#projects .project {
  background-color: var(--bgColor);
  color: var(--textColor);
  padding-bottom: 1%;
}

#projects .project .project-overlay {
  background: var(--overlayColor) !important;
  position: absolute !important;
  height: 100%  !important;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  /* padding-left: 10px; */
  transition: all 0.4s ease-in-out 0s;
}

#projects .project:hover .project-overlay {
  opacity: 0.9;
}

#projects .project:hover .mask-btn {
  box-shadow: none;
}

#projects .project-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out 0s;
}

#projects .project:hover .project-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

#projects .project:hover .card {
  opacity: 0.9;
}

#projects .fadeIn-bottom {
  top: 80%;
}

#projects .mask-btn,
#projects .mask-btn:hover,
#projects .mask-btn:active {
  border-color: none;
  box-shadow: none;
}

#projects .popup {
  /* margin: 0 auto; */
  /* margin-top: 5%; */
  vertical-align: middle;
}

#projects .vertical-align {
  margin: auto;
}

#projects .project-content {
  margin-top: 30px;
}

#projectImage {
  width: 80px;
  height: 80px;
}

#projects .popup-close {
   width: 34px;
    height: 34px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translate(50%, -50%);
    border-radius: 100%;
    background: transparent;
    border: solid 4px #808080;
}

#projects .popup-close:after,
#projects .popup-close:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 5px;
  height: 4px;
  width: 16px;
  border-radius: 30px;
  background: #808080;
  transform: rotate(45deg);
}

#projects .popup-close:after {
  transform: rotate(-45deg);
}

#projects .popup-close:hover {
  transform: translate(50%, -50%) rotate(180deg);
  background: #f00;
  text-decoration: none;
  border-color: #f00;
}

#projects .popup-close:hover:after,
#projects .popup-close:hover:before {
  background: #fff;
}

#projects .btn:focus,
#projects .btn:active {
  outline: none !important;
  box-shadow: none;
}

#projects .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1100; /* Above the backdrop */
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
}

#projects .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); 
    z-index: 1000; 
}

#projects .modal-content {
  background-color: var(--navbarColor);
}

#projects .modal-header {
  border: none;
}





#projects .projectsMenu{
  padding-bottom: 10%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #projects .popup {
    /* margin-top: 12%; */
  }

  .projectsMenu {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .projectsContent {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  #projectImage {
    height: 100px;
    width: 100px;
  }

  #projects .card {
    min-height: 160px;
    max-height: 250px;
  }
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #projects .popup {
    /* margin-top: 9%; */
  }
  #projectImage {
    height: 60px;
    width: 60px;
  }

  #projects .card {
    min-height: 160px;
    max-height: 250px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #projects .popup {
    /* margin-top: 5%; */
  }
  #projectImage {
    height: 80px;
    width: 80px;
  }

  #projects .card {
    min-height: 180px;
    max-height: 230px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #projects .popup {
    /* margin-top: 4%; */
  }

  #projectImage {
    height: 100px;
    width: 100px;
  }

  #projects .card {
    min-height: 150px;
    max-height: 200px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #projects .popup {
    /* margin-top: 2%; */
  }

  #projectImage {
    align-self: center;
    height: 200px;
    width: 200px;
  }

  #projects .card {
    min-height: 130px;
    max-height: 180px;
  }
}

#aboutMe {
    width: 100%;
    margin-bottom: 4%;
    padding-top: 1%;
    min-height: 100vh;
    background-color: var(--bgColor);
    color: var(--textColor);
    font-family: var(--fontFamily);
    font-size: 20px;
}

#aboutMe #skills{
    padding-top: 3%;
}

#aboutMe p {
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 2;
    font-weight: 400;
    display: block;
    -webkit-margin-before: 1em;
            margin-block-start: 1em;
    -webkit-margin-after: 1em;
            margin-block-end: 1em;
    -webkit-margin-start: 0px;
            margin-inline-start: 0px;
    -webkit-margin-end: 0px;
            margin-inline-end: 0px;
}

#aboutMe ol{
    margin-top: 9px;
}

/* #aboutMe .bio{
    margin-left: 50px;
} */

/* #aboutMe .iconBlock{
    
    position: relative;
    display:inline-block;
    margin-left: -40px;

} */

#aboutMe .icon{
    width:30px;
    height:30px;
    display: inline-block;
    /* vertical-align: -1.7em; */
    /* display:inline-block; */
}

#aboutMe #name{
    color: var(--highlightColor)
}

#aboutMe #role-name{
    color: var(--highlightColor)
}

#aboutMe .list{
    display: flex;
    margin:10px 0px;
}

#aboutMe .title{
    min-width: 90px;
    display: inline;
    margin-right: 20px;
    font-weight: 500;
}

#aboutMe .value{
    display: inline-block;
    margin-right: 20px;
}

#aboutMe .heading{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height: 1.4;
}

#aboutMe li{
    margin: 0;
	padding: 10px 0 10px 30px;
	list-style: none;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 22px;
}

/* #aboutMe .aboutMemainContainer{
    padding-top: 2%;
} */

/* #aboutMe .descBlock{
    padding-top: 2%;
} */
/* #aboutMe .containerBlock{
    padding:100px;
    
} */

#aboutMe .skillName{
    padding-top:10%;
    font-size:15px;
}

#aboutMe .skillIcons:hover .skillName{
   color:var(--highlightColor);
  }

#aboutMe .imageBlock{
    
    text-align: center;
    border-radius: 50%;
    
    padding: 10px;
    /* animation: spin 5s infinite linear; */
}

#aboutMe .imageBlock img {
  max-width: 100%;
  height: auto;
}

#aboutMe .myphoto{
    height:auto ;
    
    width: 100%;
    /* margin-top: 10%; */
    /* border: 0.1px solid  gray!important; */
    border-radius: 50%; 
    filter: blur(0.3px);
    
    -webkit-filter: blur(0.3px);
    /* animation: spin 5s infinite reverse linear; */
}



#aboutMe .iconImage{
    filter: grayscale(1); /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    -o-filter: grayscale(1); 
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
/* 
    -webkit-transition: all .1s ease-in-out; 
    -o-transition: all .1s ease-in-out; 
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out; */

    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out; 
    -o-transition: all .1s ease-in-out; 
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
}

#aboutMe .iconImage:hover {
    filter: none;
    -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
    -o-filter: grayscale(0); 
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -o-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
}

#aboutMe .skillImage{
    height: 70px;
    width: 70px;
    display: inline;
    /* background-color: #333; */
    
}

#aboutMe  .skillHeading{
    color: var(--textColor);
    text-align: center;
    padding-bottom: 20px;
    text-transform: uppercase;
}

#aboutMe .highlightText{
    color: var(--highlightColor);
}

#aboutMe .skillBlock{
    justify-content: center;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

#aboutMe .skillIcons{
    margin: 10px!important;
    
    padding: 10px!important;
    /* opacity: .8!important; */
    border: 0.1px solid  gray!important;
    vertical-align: middle!important;
    text-align: center!important;
    border-radius: 5px!important;
    display: table!important;
    /* box-shadow: 4px 5px 4px 3px rgba(89,4,168,.137)!important; */
    overflow: hidden!important;
    /* transition: all .4s ease 0s!important; */

    /* filter: grayscale(1); 
    -webkit-filter: grayscale(1); 
    -o-filter: grayscale(1); 
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1); */

    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out; 
    -o-transition: all .1s ease-in-out; 
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;

}


#aboutMe .skillIcons:hover {
    filter: none;
    -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
    -o-filter: grayscale(0); 
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -o-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
}


@media (max-width: 768px) {
    #aboutMe .descBlock{
        padding-top: 10%;
    }
}
#certificates {
    width: 100%;
    padding-top: 1%;
    /* margin-bottom: 4%; */
    color: var(--textColor);
    min-height: 100vh;
    background-color: var(--bgColor);
    font-size: 20px;
}

#certificates .card {
    border: 1px solid var(--dividerColor);
    background-color: transparent;
    min-height: 220px;
    max-height: 220px;
  }

  #certificates .card-course-title-div{
    min-height: 80px;
    max-height: 80px;
  }

  #certificates .card-course-title{
    text-align: center;
    vertical-align:middle;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  #certificates .certificate-info{
    text-align: center;
  }

  #certificates .card-image-logo-div{
    border-radius: 10px;
    text-align: center;
  }

  #certificates .card-image-logo{
      height: 120px;
      width: 120px;
      display: inline !important;
  }
  
  #certificates .certificate {
    background-color: var(--bgColor);
    color: var(--textColor);
  }
  
  #certificates .certificate-overlay {
    background: var(--overlayColor);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    /* padding-left: 10px; */
    transition: all 0.4s ease-in-out 0s;
  }
  
  #certificates .certificate:hover .certificate-overlay {
    opacity: 0.5;
  }
  
  #certificates .certificate:hover .mask-btn {
    box-shadow: none;
  }
  
  #certificates .certificate-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-in-out 0s;
  }
  
  #certificates .certificate:hover .certificate-details {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  #certificates .certificate:hover .card {
    opacity: 0.7;
  }
  
  #certificates .fadeIn-bottom {
    top: 80%;
  }
  
  #certificates .mask-btn,
  #certificates .mask-btn:hover,
  #certificates .mask-btn:active {
    border-color: none;
    box-shadow: none;
  }
  
#certificates  .popup {
    /* margin: 0 auto; */
    /* margin-top: 5%; */
    vertical-align: middle;
  }
  
  #certificates .vertical-align {
    margin: auto;
  }
  
  #certificates .certificate-content {
    margin-top: 30px;
  }
/*   
  #certificates #projectImage {
    width: 80px;
    height: 80px;
  } */
  
  #certificates .popup-close {
    width: 34px;
    height: 34px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    transform: translate(50%, -50%);
    border-radius: 100%;
    background: transparent;
    border: solid 4px #808080;
  }
  
  #certificates .popup-close:after,
  #certificates .popup-close:before {
    content: "";
    position: absolute;
    top: 11px;
    left: 5px;
    height: 4px;
    width: 16px;
    border-radius: 30px;
    background: #808080;
    transform: rotate(45deg);
  }
  
    #certificates .popup-close:after {
    transform: rotate(-45deg);
  }
  
  #certificates .popup-close:hover {
    transform: translate(50%, -50%) rotate(180deg);
    background: #f00;
    text-decoration: none;
    border-color: #f00;
  }
  
  #certificates .popup-close:hover:after,
  #certificates .popup-close:hover:before {
    background: #fff;
  }
  
  #certificates .btn:focus,
  #certificates .btn:active {
    outline: none !important;
    box-shadow: none;
  }

 #certificates .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1100; /* Above the backdrop */
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
}

 #certificates .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent black */
    z-index: 1000; /* Below the modal */
}
  
  #certificates .modal-content {
    background-color: var(--navbarColor);
  }
  
  #certificates .modal-header {
    border: none;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .popup {
      /* margin-top: 12%; */
    }
  
    .projectsMenu {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  
    .projectsContent {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  
    #projectImage {
      height: 100px;
      width: 100px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .popup {
      /* margin-top: 9%; */
    }
    #projectImage {
      height: 60px;
      width: 60px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .popup {
      /* margin-top: 5%; */
    }
    #projectImage {
      height: 80px;
      width: 80px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .popup {
      /* margin-top: 4%; */
    }
  
    #projectImage {
      height: 100px;
      width: 100px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .popup {
      /* margin-top: 2%; */
    }
  
    #projectImage {
      align-self: center;
      height: 200px;
      width: 200px;
    }
  }
  
