/* html, body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100% !important;
    overflow-x: hidden !important;
} */

#projects {
  width: 100%;
  padding-top: 1%;
  min-height: 100vh;
  background-color: var(--bgColor);
  color: var(--textColor);
  font-family: var(--fontFamily);
  margin-bottom: 4%;
  font-size: 16px;
}

#projects .projectLink{
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linearlinear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s ealinearse;
}

#projects .project-contribution{
  list-style-type: square !important;
}

.nav-pills .nav-item.active {
  color: var(--textColor);
  background: None;
  font-family: var(--fontFamily);
}

.nav-item {
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: var(--fontFamily);
  /* font-weight: bold; */
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: None !important;
  color: var(--highlightColor) !important;
  font-weight: bold !important;
}


#projects .fade{
  transition: transform 0.5s linear;
  transition-property: opacity;
}

.nav-link {
  border: None;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: none;
  border: None;
  color: var(--highlightColor);
}

.nav-item a {
  color: var(--textColor);
  top: 0;
}

.nav-item:hover .nav-link:hover {
  top: 0;
  border: None;
  color: var(--highlightColor);
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}

.tab-list {
  border: none;
}

#projects .card {
  border: 1px solid var(--textColor);
  background-color: transparent;
  min-height: 150px;
  max-height: 250px;
}

#projects .prImage {
  text-align: center;
}

#projects .project {
  background-color: var(--bgColor);
  color: var(--textColor);
  padding-bottom: 1%;
}

#projects .project .project-overlay {
  background: var(--overlayColor) !important;
  position: absolute !important;
  height: 100%  !important;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  /* padding-left: 10px; */
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

#projects .project:hover .project-overlay {
  opacity: 0.9;
}

#projects .project:hover .mask-btn {
  box-shadow: none;
}

#projects .project-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

#projects .project:hover .project-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}

#projects .project:hover .card {
  opacity: 0.9;
}

#projects .fadeIn-bottom {
  top: 80%;
}

#projects .mask-btn,
#projects .mask-btn:hover,
#projects .mask-btn:active {
  border-color: none;
  box-shadow: none;
}

#projects .popup {
  /* margin: 0 auto; */
  /* margin-top: 5%; */
  vertical-align: middle;
}

#projects .vertical-align {
  margin: auto;
}

#projects .project-content {
  margin-top: 30px;
}

#projectImage {
  width: 80px;
  height: 80px;
}

#projects .popup-close {
   width: 34px;
    height: 34px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 100%;
    background: transparent;
    border: solid 4px #808080;
}

#projects .popup-close:after,
#projects .popup-close:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 5px;
  height: 4px;
  width: 16px;
  border-radius: 30px;
  background: #808080;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#projects .popup-close:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#projects .popup-close:hover {
  -webkit-transform: translate(50%, -50%) rotate(180deg);
  transform: translate(50%, -50%) rotate(180deg);
  background: #f00;
  text-decoration: none;
  border-color: #f00;
}

#projects .popup-close:hover:after,
#projects .popup-close:hover:before {
  background: #fff;
}

#projects .btn:focus,
#projects .btn:active {
  outline: none !important;
  box-shadow: none;
}

#projects .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1100; /* Above the backdrop */
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
}

#projects .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); 
    z-index: 1000; 
}

#projects .modal-content {
  background-color: var(--navbarColor);
}

#projects .modal-header {
  border: none;
}





#projects .projectsMenu{
  padding-bottom: 10%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #projects .popup {
    /* margin-top: 12%; */
  }

  .projectsMenu {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .projectsContent {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  #projectImage {
    height: 100px;
    width: 100px;
  }

  #projects .card {
    min-height: 160px;
    max-height: 250px;
  }
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #projects .popup {
    /* margin-top: 9%; */
  }
  #projectImage {
    height: 60px;
    width: 60px;
  }

  #projects .card {
    min-height: 160px;
    max-height: 250px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #projects .popup {
    /* margin-top: 5%; */
  }
  #projectImage {
    height: 80px;
    width: 80px;
  }

  #projects .card {
    min-height: 180px;
    max-height: 230px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #projects .popup {
    /* margin-top: 4%; */
  }

  #projectImage {
    height: 100px;
    width: 100px;
  }

  #projects .card {
    min-height: 150px;
    max-height: 200px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #projects .popup {
    /* margin-top: 2%; */
  }

  #projectImage {
    align-self: center;
    height: 200px;
    width: 200px;
  }

  #projects .card {
    min-height: 130px;
    max-height: 180px;
  }
}
